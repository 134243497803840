<template>
    <hero />
</template>
<script>
import Hero from './hero'

export default {
    name: `Home`,

    metaInfo: { title: `Home` },

    components: { Hero },

    props: {
        id: {
            type: String,
            default: `home`
        }
    }
}
</script>
